import * as React from "react";
import Layout from "../components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { graphql } from "gatsby";
import { PortableText } from "@portabletext/react";
import styled from "styled-components";

const LegalWrapper = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 150px;
  
  h1, h2, h3 {
    word-break: break-all;
  }

  @media (min-width: 1200px) {
    margin-top: 250px;
  }
`;

const LegalInner = styled.div`
  padding: 25px;
`;

const AgbTemplatePage = ({ location, data }) => {
  const ptComponents = {
    marks: {
      link: ({ children, value }) => {
        const rel = !value.href.startsWith("/")
          ? "noreferrer noopener"
          : undefined;
        return (
          <a href={value.href} rel={rel} target="_blank">
            {children}
          </a>
        );
      },
    },
  };
  return (
    <Layout path={location.pathname}>
      <GatsbySeo
        title={data.sanityAgb.seo.title}
        description={data.sanityAgb.seo.description}
      />
      <LegalWrapper>
        <LegalInner>
          <h1>{data.sanityAgb.title}</h1>
          <PortableText
            value={data.sanityAgb.content}
            components={ptComponents}
          />
        </LegalInner>
      </LegalWrapper>
    </Layout>
  );
};

export const query = graphql`
  query AgbTemplateQuery {
    sanityAgb {
      title
      content: _rawContent
      seo {
        title
        description
      }
    }
  }
`;

export default AgbTemplatePage;
